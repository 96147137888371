// $primary: #fc6ac2;
// $secondary: white;
// $accent: #fa32aa;
// $background-color: #fceef6;
// $background-accent: #ffdff1;
// $button-bg-color: #f86cc0;
// $button-bg-hover-color: #fc4ab5;
// $text-color: rgba(87, 87, 87, 0.9);
$primary: #696969;
$secondary: white;
$accent: #707070;
$background-color: #f0f0f0;
$background-accent: #d3d3d3;
$secondary-bg-color: #ffde01;
$button-bg-color: #afafaf;
$button-bg-hover-color: #a7a7a7;
$text-color: rgba(87, 87, 87, 0.9);
