@import '../../assets/sass/styles.scss';
.Main {
  background-color: $background-color;
  .prizes {
    text-align: center;
    padding: 3rem 0;
    img {
      max-height: 40vh;
    }
  }
  .footer {
    background-color: $background-accent;
    .container {
      text-align: end;
    }
  }
}

@media screen and (max-width: 769px) {
  .Main {
    .prizes {
      padding: 2rem;
    }
  }
}
